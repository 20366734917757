<template>
  <div class="text-center text-danger">
    <h3>Erro 404 :(</h3>
    <h5>Você não tem permissão para acessar esta página</h5>
    <div class="mt-5 pt-5 text-secondary">
      <router-link :to="$store.state.menu[0].link">Página Inicial</router-link>
      <a href="/logout" class="ml-4">Logout</a>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>